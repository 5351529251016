import Lightbox from '@/Components/Common/Lightbox.jsx';
import YaKCatchPhraseBox from '@/Pages/Configurator/Components/Themed/YaKCatchPhraseBox.jsx';
import ImagesStyle from '@/Styles/images.js';
import {Box, Link as MuiLink, Typography} from '@mui/material';
import React, {memo} from 'react';

const decors = [
    {image: '/theme/ya-k/images/pages/configurator/decors/photos-chambre-Photo01.jpg', width: 1000, height: 660},
    {image: '/theme/ya-k/images/pages/configurator/decors/photos-chambre-Photo02.jpg', width: 1000, height: 1000},
    {image: '/theme/ya-k/images/pages/configurator/decors/photos-chambre-Photo03.jpg', width: 1000, height: 660},
];

let styles = {
    bed_icon: (theme) => ({
        position: 'relative',
        display: 'inline-block',
        '&::after': {
            ...ImagesStyle.background_contain('/theme/ya-k/images/icons/lit.svg'),
            content: '""',
            zIndex: 999,
            position: 'absolute',
            top: 0,
            right: '22%',
            transform: 'translateY(-100%)',
            width: '64px',
            height: '47px',
            [theme.breakpoints.up('md')]: {
                width: '96px',
                height: '70px',
            },
        },
    }),
};

const YaKBedroomsDetails = () => {
    return (
        <>
            <Box sx={{mb: {xs: 4, md: 6}, ml: '44px'}}>
                <Typography variant="h5" component="h2" sx={{textWrap: 'balance'}}>
                    Côté chambre <strong>on s'organise !</strong>
                </Typography>
            </Box>

            <Lightbox
                id={'gallery-bedroom-organisation'}
                sx={(theme) => ({
                    display: 'grid',
                    gridTemplateAreas: `
                        "a"
                        "b"
                        "c"
                        "d"
                        "e"`,
                    gap: 5,
                    height: '100%',
                    width: '100%',
                    [theme.breakpoints.up('sm')]: {
                        gridTemplateAreas: `
                        "a a"
                        "b c"
                        "d d"
                        "e e"`,
                    },
                    [theme.breakpoints.up('md')]: {
                        gridTemplateColumns: '1fr 1fr',
                        gridTemplateAreas: `
                            "a c"
                            "b c"
                            "d e"`,
                        gap: 8,
                    },
                    [theme.breakpoints.up('lg')]: {
                        gridTemplateColumns: '1fr 1fr 1fr',
                        gridTemplateAreas: `
                            "a c d"
                            "b c d"
                            "b c e"`,
                    },
                })}
            >
                <YaKCatchPhraseBox
                    sx={{gridArea: 'a', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                >
                    <Typography variant="subtitle2" component="p" sx={{textAlign: 'justify'}}>
                        Un dressing, un valet de chambre, … à vous d'écrire l'aménagement qui vous correspond. Caché,
                        ouvert, avec une niche déco', composez avec des rangements qui vous faciliteront la vie.
                    </Typography>
                </YaKCatchPhraseBox>

                <MuiLink
                    href={decors[0].image}
                    data-pswp-width={decors[0].width}
                    data-pswp-height={decors[0].height}
                    target="_blank"
                    rel="noreferrer"
                    sx={(theme) => ({
                        ...styles.bed_icon(theme),
                        display: 'inline-block',
                        gridArea: 'b',
                    })}
                >
                    <Box component="img" src={decors[0].image} style={{...ImagesStyle.cover_center}} alt="" />
                </MuiLink>
                <MuiLink
                    href={decors[1].image}
                    data-pswp-width={decors[1].width}
                    data-pswp-height={decors[1].height}
                    target="_blank"
                    rel="noreferrer"
                    sx={{
                        display: 'inline-block',
                        gridArea: 'c',
                    }}
                >
                    <Box component="img" src={decors[1].image} style={{...ImagesStyle.cover_center}} alt="" />
                </MuiLink>
                <MuiLink
                    href={decors[2].image}
                    data-pswp-width={decors[2].width}
                    data-pswp-height={decors[2].height}
                    target="_blank"
                    rel="noreferrer"
                    sx={{
                        display: 'inline-block',
                        gridArea: 'd',
                    }}
                >
                    <Box component="img" src={decors[2].image} style={{...ImagesStyle.cover_center}} alt="" />
                </MuiLink>
                <YaKCatchPhraseBox
                    sx={{
                        gridArea: 'e',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography variant="subtitle2" component="p" sx={{textAlign: 'justify'}}>
                        Bucolique, contemporain ou bohème, choisissez un univers qui facilitera votre sommeil. Papier
                        peint panoramique, peinture ou effet de texture, lino ou parquet, réalisez un bel équilibre tout
                        en nuance pour un espace intimiste.
                    </Typography>
                </YaKCatchPhraseBox>
            </Lightbox>
        </>
    );
};

export default memo(YaKBedroomsDetails);
